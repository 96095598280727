
import { defineComponent } from 'vue';
import Showcase from '@/components/Showcase.vue';

export default defineComponent({
	name: 'HomeView',
	components: {
		Showcase
	}
});
