<template>
	<router-view />
</template>

<style lang="scss">
* {
	font-family: Arial, Helvetica, sans-serif;
	box-sizing: border-box;
}

body {
	background-color: #fff;
}
</style>
